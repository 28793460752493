import { cn } from "@/lib/utils";
import { Logo } from "@/components/atoms/logo";
import AuthButton from "@/components/molecules/auth-button";

export default function Header() {
  return (
    <header
      className={cn("container w-full h-14 flex items-center justify-between")}
    >
      <Logo />
      <AuthButton />
    </header>
  );
}
