"use client";
import { LoginForm } from "@/components/organisms/login-form";
import { redirect } from "next/navigation";
import { useAuth } from "@aether/shared/context/auth";
import LandingCardInfo from "@/components/molecules/landing-card-info";
import { useEffect } from "react";

export default function Home() {
  const { user, isLoading } = useAuth();

  useEffect(() => {
    if (user && !isLoading) {
      return redirect("/collections");
    }
  }, [user, isLoading]);

  return (
    <main className="container flex flex-col p-10 mx-auto lg:flex-row gap-4 min-h-screen">
      <div className="w-full md:flex md:flex-col">
        <h1 className="text-5xl md:text-5xl font-bold leading-normal text-sky-500">
          Discover, Capture, Inspire with AetherLenz
        </h1>
        <div className="flex p-3 my-5">
          <h3 className="text-lg md:text-2xl  leading-loose text-neutral-800">
            Look for photography captains, ask for inspiring shots, and find
            your photos here in seconds.
          </h3>
        </div>
        <div className="grid sm:grid-cols-3 items-end justify-center gap-3">
          <LandingCardInfo
            url="/images/landing-page/register.jpg"
            title="Sign up for free!"
          />
          <LandingCardInfo
            url="/images/landing-page/photographer.jpg"
            title="Approach our photography captains"
          />
          <LandingCardInfo
            url="/images/landing-page/searched-photo.jpg"
            title="Find photos with your face."
          />
        </div>
      </div>
      <div className="min-w-md max-w-lg h-fit w-full border p-6 rounded-lg shadow-lg mx-auto">
        <LoginForm />
      </div>
    </main>
  );
}
